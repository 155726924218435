import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from 'react-apollo'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import client from './apollo'
import theme from './styles/theme'

import indexRoutes from './routes'

const GlobalStyle = createGlobalStyle`
  body {
    text-rendering: optimizeLegibility;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    line-height: 24px;
  }
  * {
    box-sizing: border-box;
  }
`

ReactDOM.render(
  <ApolloProvider client={client}>
    <>
    <GlobalStyle />
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          {indexRoutes.map((prop, key) => {
            return (
              <Route
                path={prop.path}
                component={prop.component}
                key={key}
              />
            )
          })}
        </Switch>
      </Router>
    </ThemeProvider>
    </>
  </ApolloProvider>,
  document.getElementById('root')
)
