import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import { Input, Button } from 'ur-majorelle'

import { StyleBase, FormStyle } from './style'
import { adminLogin } from '../../actions/admin'
import Logo from '../../assets/img/logo.svg'

class LoginView extends Component {
  state = {
    email: '',
    password: '',
    error: null
  }

  componentWillMount () {
    if (!!localStorage.getItem('Authorization')) {
      this.props.history.push('/candidates')
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value,
      error: null
    })
  }

  onSubmit = async (e) => {
    e.preventDefault()
    try {
      const { data: { adminLogin } } = await this.props.mutate({
        variables: {
          email: this.state.email,
          password: this.state.password
        }
      })
      localStorage.setItem('Authorization', adminLogin.token)
      this.props.history.push('/')
    } catch (e) {
      if (e.message === "GraphQL error: invalid_credentials") {
        this.setState({ error: 'Invalid email or password.' })
      } else {
        this.setState({ error: 'Opps! something went wrong.' })
      }
    }
  }

  render () {
    const { email, password, error } = this.state
    return (
      <StyleBase>
        <img src={Logo} alt="Eval" style={ { width: '160px' } }/>
        <FormStyle onSubmit={this.onSubmit}>
          <h3>Login</h3>
          {
            !!error && (
              <p className="error-msg">{error}</p>
            )
          }
          <Input
            id="login-email"
            label="Email"
            onChange={this.handleInputChange}
            type="email"
            name="email"
            value={email}
            placeholder="john@doe.com"
            required />  
                  
          
          <Input
            id="login-password"
            label="Password"
            onChange={this.handleInputChange}
            type="password"
            name="password"
            value={password}
            placeholder="*****"
            required />


          <Button type="submit">Sign in</Button>
        </FormStyle>
        <p className="copy">&copy; 2020 Gemography</p>
      </StyleBase>
    )
  }
}

export default graphql(adminLogin)(LoginView)
