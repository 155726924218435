import styled from 'styled-components'

export const StyleBase = styled.div`
  background-color: ${props => props.theme.secondary};
  min-height: 100vh;
  padding-top: 10%;
  img {
    display: block;
    margin: 0 auto;
  }
  p.copy {
    text-align: center;
    color: #FFF;
    font-size: 12px;
  }
  p.error-msg {
    background-color: rgba(226, 57, 67, .1);
    color: rgba(226, 57, 67, 1);
    border: 1px solid rgba(226, 57, 67, 1);
    font-size: 12px;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
  }
  h3 {
    margin: 0 0 24px;
    font-size: 24px;
  }
`

export const FormStyle = styled.form`
  background-color: #FFF;
  border-radius: 4px;
  max-width: 430px;
  margin: 90px auto 40px;
  padding: 40px 40px 30px;
  box-shadow: 0 3px 6px 0 rgba(0,0,0,0.23), 0 3px 6px 0 rgba(0,0,0,0.16);
  button {
    width: 100%;
  }
  > div {
    margin-bottom: 24px;
  }
`
