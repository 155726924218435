import React, { Component } from 'react'
import { NavLink, Link } from 'react-router-dom'

import dashboardRoutes from '../../routes/dashboard'

import { StyleBase, SiderbarWrapper, BackDrop } from './style'
import Logo from '../../assets/img/logo.svg'

class Sidebar extends Component {
  activeRoute (routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : ''
  }
  render () {
    const { sideBarShown } = this.props
    return (
      <>
        <BackDrop onClick={this.props.toggleSideBar} sideBarShown={sideBarShown}/>
        <StyleBase sideBarShown={sideBarShown}>
          <div className="logo">
            <img src={Logo} width="105" alt="Eval"/>
          </div>
          <SiderbarWrapper role="navigation">
            <ul>
              {dashboardRoutes.map((prop, key) => {
                if (!prop.redirect && !prop.child)
                  return (
                    <li onClick={() => sideBarShown && this.props.toggleSideBar()} className={this.activeRoute(prop.path)} key={key}>
                      <NavLink
                        to={prop.path}
                        className="nav-link"
                        activeClassName="active">
                        <span>{prop.name}</span>
                      </NavLink>
                    </li>
                  )
                return null
              })}
              <li className="signout">
                <Link to="/signout">
                  <svg width="24px" height="30px" viewBox="0 0 24 30" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="Quizzes" transform="translate(-103.000000, -944.000000)" fill="#878787" fillRule="nonzero">
                        <g id="001-logout-1" transform="translate(103.000000, 944.000000)">
                          <path d="M14.6491014,28.8235547 L2.42231884,28.8235547 C1.78318841,28.8235547 1.20324638,28.2581836 1.20324638,27.6124805 L1.20324638,2.30800781 C1.20324638,1.66230469 1.78324638,1.17650391 2.42231884,1.17650391 L14.6491014,1.17650391 C14.9704928,1.17650391 15.2310725,0.913417969 15.2310725,0.58828125 C15.2310725,0.263085938 14.9704348,0 14.6491014,0 L2.42231884,0 C1.14156522,0 0.0392463768,1.01320312 0.0392463768,2.30800781 L0.0392463768,27.6124805 C0.0392463768,28.9072266 1.14156522,30 2.42231884,30 L14.6491014,30 C14.9704928,30 15.2310725,29.7369141 15.2310725,29.4117773 C15.2310725,29.0866406 14.9704348,28.8235547 14.6491014,28.8235547 Z" id="Shape"></path>
                          <path d="M23.8113043,14.2726172 L18.5734493,8.39027344 C18.3583188,8.14845703 17.9903188,8.12947266 17.7516522,8.34662109 C17.5126377,8.56376953 17.4933333,8.95599609 17.7084638,9.19722656 L22.072058,14.1176953 L8.82921739,14.1176953 C8.50782609,14.1176953 8.24724638,14.3807813 8.24724638,14.705918 C8.24724638,15.0310547 8.50782609,15.2941406 8.82921739,15.2941406 L21.9738551,15.2941406 L17.7294493,19.5642773 C17.502087,19.794082 17.502087,20.15625 17.7294493,20.3860547 C17.8431304,20.500957 17.992,20.5535156 18.1409275,20.5535156 C18.2898551,20.5535156 18.4387246,20.4936328 18.5524058,20.3787305 L23.7902609,15.0833203 C24.0091014,14.8621289 24.0184928,14.5052344 23.8113043,14.2726172 Z" id="Shape"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <span>Sign out</span>
                </Link>
              </li>
            </ul>
          </SiderbarWrapper>
        </StyleBase>
      </>
    )
  }
}

export default Sidebar
