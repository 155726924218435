import styled, { css, keyframes } from 'styled-components'

export const StyleBase = styled.div`
  background-color: ${props => props.theme.secondary};
  width: 200px;
  flex-shrink: 0;
  .logo {
    margin: 56px 43px 100px;
  }
  position: fixed;
  top: 0;
  bottom: 0;
  @media screen and (max-width: 920px) {
    z-index: 999;
    transition: .3s all;
    transform: ${props => props.sideBarShown ? 'translateX(0px)' : 'translateX(-300px)'};
  }
`

export const SiderbarWrapper = styled.nav`
  overflow: auto;
  ul {
    padding: 0;
  }
  li {
    border-left: 3px solid transparent;
    margin-bottom: 14px;
    &.signout {
      position: absolute;
      bottom: 0;
    }
    a {
      color: #FFF;
      opacity: .5;
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: 5px 38px;
      font-family: 'Montserrat', 'Roboto', sans-serif; 
      font-weight: 500;
      line-height: 24px;
      font-size: 14px;
    }
    svg {
      display: inline-block;
      height: 20px;
      width: 30px;
      background-repeat: no-repeat;
      margin-right: 15px;
    }
    &:hover a {
      opacity: .9;
    }
    &.active {
      border-left: 5px solid #FFF;
      background-color: rgba(255, 255, 255, .1);
      a {
        opacity: 1;
        font-weight: 700;
      }
      svg {
        #candidates-fill,
        #quizzes-fill path, #quizzes-fill use,
        #team-fill {
          stroke: ${props => props.theme.primary};
        }
        #questions-fill {
          fill: ${props => props.theme.primary};
        }
      }
    }
  }
`

const fade = keyframes`
  0% {
    left: 0;
    opacity: 0;
  }
  100% {
    left: 200px;
    opacity: 1;
  }
`

export const BackDrop = styled.div`
  position: fixed;
  ${props => props.sideBarShown && css`
    animation-name: ${fade};
    animation-delay: .15s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    animation-duration: .15s;
    @media screen and (max-width: 920px) {
      top: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0,0,0,.3);
      z-index: 999;
    }
  `}
`

export const Menu = styled.div`
  @media screen and (min-width: 920px) {
    display: none;
  }
  position: fixed;
  top: 35px;
  left: 35px;
  span {
    height: 3px;
    width: 22px;
    background-color: ${props => props.theme.darkGray};
    display: block;
    margin-bottom: 3px;
    &:last-child {
      margin: 0;
    }
  }
` 
