import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { DashboardCtx } from './dashboard-ctx'

import { StyleBase, MainPanel, Menu } from './style'
// import Header from '../../components/Header'
import Sidebar from '../../components/Sidebar'
import Modal from '../../components/Modal'
import PrivateRoute from '../../hocs/withAuth'

import dashboardRoutes from '../../routes/dashboard'

import { Notification } from 'ur-majorelle'

class Dashboard extends Component {
  constructor (props) {
    super(props)

    this.previousLocation = this.props.location
    
    this.state = {
      sideBarShown: false,
      showNotification: false,
      notifProps: null
    }
  }

  componentWillUpdate(nextProps) {
    const { location } = this.props
    // set previousLocation if props.location is not modal
    if (
      nextProps.history.action !== "POP" &&
      (!location.state || !location.state.modal)
    ) {
      this.previousLocation = this.props.location
    }
  }

  handleShowNotification = (props) => {
    props.onClose = () => this.setState({ showNotification: false })

    this.setState({ 
      notifProps: props,
      showNotification: true
    })

    setTimeout(() => {
      this.setState({ showNotification: false })
     }, 6000)
  }

  toggleSideBar = () => {
    this.setState({ sideBarShown: !this.state.sideBarShown })
  }

  render () {
    const { location } = this.props
    const candidateComponent = dashboardRoutes.find(x => x.name === 'Candidate Details')
    const isModal = !!(
      location.state &&
      location.state.modal &&
      this.previousLocation !== location
    )
    return (
      <DashboardCtx.Provider
        value={{showNotification: this.handleShowNotification }}>
        <StyleBase sideBarShown={this.state.sideBarShown}>
          <Notification
              isVisible={this.state.showNotification}
              {...this.state.notifProps}
            />
          <Menu onClick={this.toggleSideBar}>
            <span />
            <span />
            <span />
          </Menu>
          <Sidebar
            toggleSideBar={this.toggleSideBar}
            sideBarShown={this.state.sideBarShown}
            {...this.props} />
          <MainPanel>
            {/* <Header {...this.props} /> */}
            <Switch location={isModal ? this.previousLocation : location} >
              {
              dashboardRoutes
              .map((prop, key) => {
                if (prop.redirect)
                  return <Redirect from={prop.path} to={prop.to} key={key} />
                return (
                  <Route
                    exact={prop.exact}
                    path={prop.path}
                    render={routerProps => (
                      <prop.component
                        showNotification={this.handleShowNotification}
                        {...routerProps}
                      />
                    )}
                    key={key} />
                )
              })}
            </Switch>
            {
              isModal && (
                <Route
                  path="/candidates/:id"
                  render={routerProps => (
                    <Modal
                      width={1020}
                      onClose={e => {
                        e.stopPropagation()
                        this.props.history.goBack()
                      }}>
                      <candidateComponent.component
                        showNotification={this.handleShowNotification}
                        {...routerProps}
                      />
                    </Modal>
                  )} />
              )
            }
          </MainPanel>
        </StyleBase>
      </DashboardCtx.Provider>
    )
  }
}

export default PrivateRoute(Dashboard)
