import Loadable from 'react-loadable'
import {
  CandidatesIcon,
  QuizzesIcon,
  QuestionsIcon,
  // TeamIcon
} from './icons'
import LoadingSpinner from '../../components/Loading'

const Loader = () => null

const Submissions = Loadable({
  loader: () => import('../../views/Submissions'),
  loading: LoadingSpinner
})

const Candidates = Loadable({
  loader: () => import('../../views/Candidates'),
  loading: Loader
})

const Candidate = Loadable({
  loader: () => import('../../views/Candidate'),
  loading: LoadingSpinner
})

const Quizzes = Loadable({
  loader: () => import('../../views/Quizzes'),
  loading: Loader
})

const QuizForm = Loadable({
  loader: () => import('../../views/QuizForm'),
  loading: LoadingSpinner
})

const QuizDetails = Loadable({
  loader: () => import('../../views/Quiz'),
  loading: LoadingSpinner
})

const Questions = Loadable({
  loader: () => import('../../views/Questions'),
  loading: Loader
})

const QuestionForm = Loadable({
  loader: () => import('../../views/QuestionForm'),
  loading: LoadingSpinner
})

const QuestionDetails = Loadable({
  loader: () => import('../../views/Question'),
  loading: LoadingSpinner
})


const dashboardRoutes = [
  {
    path: '/submissions',
    name: 'Submissions',
    component: Submissions
  },
  {
    path: '/candidates',
    name: 'Candidates',
    icon: CandidatesIcon,
    component: Candidates,
    exact: true
  },
  {
    path: '/candidates/:id',
    name: 'Candidate Details',
    icon: CandidatesIcon,
    component: Candidate,
    child: true
  },
  {
    path: '/quizzes',
    name: 'Quizzes',
    icon: QuizzesIcon,
    component: Quizzes,
    exact: true
  },
  {
    path: '/quizzes/new',
    name: 'Create Quiz',
    icon: QuizzesIcon,
    component: QuizForm,
    exact: true,
    child: true
  },
  {
    path: '/quizzes/:id',
    name: 'Quiz Details',
    icon: QuizzesIcon,
    component: QuizDetails,
    exact: true,
    child: true
  },
  {
    path: '/questions',
    name: 'Questions',
    icon: QuestionsIcon,
    component: Questions,
    exact: true
  },
  {
    path: '/questions/new',
    name: 'Create question',
    icon: QuestionsIcon,
    component: QuestionForm,
    exact: true,
    child: true
  },
  {
    path: '/questions/:id',
    name: 'Question details',
    icon: QuestionsIcon,
    component: QuestionDetails,
    exact: true,
    child: true
  },
  { redirect: true, from: '/', to: '/candidates' }
]

export default dashboardRoutes
