import styled from 'styled-components'

export const StyleBase = styled.div`
  display: flex;
  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
`

export const MainPanel = styled.div`
  width: 100%;
  overflow: auto;
  min-height: 100vh;
  @media screen and (min-width: 920px) {
    margin-left: 216px;

  }
  > div {
    padding: 40px;
    margin: 0 auto;
    @media screen and (max-width: 1080px) {
      flex-direction: column;
      > div {
        width: 100%;
        flex-direction: column;
      }
    };
    @media screen and (max-width: 520px) {
      padding: 35px 15px;
    }
  }
`

export const Menu = styled.div`
  @media screen and (min-width: 920px) {
    display: none;
  }
  @media screen and (max-width: 920px) {
    padding: 30px 35px 0;
  }
  cursor: pointer;
  span {
    height: 3px;
    width: 22px;
    background-color: ${props => props.theme.darkGray};
    display: block;
    &:nth-child(2) {
      margin: 2px 0;
    }
  }
` 
