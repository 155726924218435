const theme = {
  primary: '#6050DC',
  secondary: '#0B0826',
  darkGray: '#212121',
  gray: '#909090',
  lightGrey: '#DEDEDE',
  silver: '#F5F6FA',
  hoverBgColor: "#fafafd",
  green: '#0CCE6B',
  blackTitle: '#2D2D2D',
  inputBorder: '#E4E4E4',
  danger: '#F6405F',
  tags: {
    color: '#FFFFFF',
    bg: 'rgba(135, 135, 135, 0.5)'
  },
  aluminumGray: '#F7F7F7',
  quizColors: {
    android: 'linear-gradient(-270deg, #3DCD58 0%, #70E891 100%)',
    ios: 'linear-gradient(-270deg, #F77A47 0%, #FCB27D 100%)',
    backend: 'linear-gradient(-270deg, #2C3137 0%, #2C3137 100%)',
    frontend: 'linear-gradient(-270deg, #0084FF 0%, #00BAFF 100%)'
  },
  quizInfoHeader: {
    android: '#3DCD58',
    ios: '#F77A47',
    backend: '#2C3137',
    frontend: '#0084FF'
  },
  question: {
    correct: '#3DCD58',
    wrong: '#E23943',
    bg: '#ECF9EF'
  },
  submissionStatus: {
    completed: {
      bg: '#D8F6DD',
      color: '#3DCD58'
    },
    pending: {
      bg: '#FDEDD3',
      color: '#F5A623'
    }
  },
  submissionTop: {
    one: {
      bg: '#DAA520',
      color: '#ffffff',
      modalBG: '#FEF9ED'
    },
    five: {
      bg: '#DDDDDD',
      color: '#222222',
      modalBG: '#F7F7F7'
    },
    ten: {
      bg: '#D17B4F',
      color: '#FFFFFF',
      modalBG: '#F9EFEA'
    }
  },
  scoreCircle: {
    high: '#0CCE6B',
    low: '#E23943'
  }
}

export default theme
