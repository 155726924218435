import gql from 'graphql-tag'

export const currentAdmin = gql`
  query {
    currentAdmin {
      _id
      name
      email
    }
  }
`

export const adminLogin = gql`
  mutation ($email: String!, $password: String!) {
    adminLogin (email: $email, password: $password) {
      token
      me {
        _id
        name
        email
      }
    }
  }
`
