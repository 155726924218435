import React from 'react'

export const CandidatesIcon = () =>
  <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" >
    <g id="candidates-fill" transform="translate(-3 -3)" stroke="#878787" strokeWidth="1.5" fill="none" fillRule="evenodd">
      <rect x="3.75" y="3.75" width="16.5" height="16.5" rx="2"/>
      <path d="M6.00221744,20.0011091 C6.00221744,16.2504159 10.5013859,17.0005546 10.5013859,15.5002773 C10.5013859,15.5002773 10.5877186,14.5041536 10.091845,14 C9.47410352,13.3750901 9.00166308,12.6239722 9.00166308,11.3745148 C9.00166308,9.51020916 10.3445606,7.99889087 12.0011087,7.99889087 C13.6576568,7.99889087 15.0005544,9.51020916 15.0005544,11.3745148 C15.0005544,12.6063746 14.5404686,13.3740426 13.8954954,14 C13.4242571,14.4915107 13.5008315,15.5002773 13.5008315,15.5002773 C13.5008315,17.0005546 18,16.2504159 18,20.0011091"/>
    </g>
  </svg>

export const QuizzesIcon = () =>
  <svg width="28" height="21" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M23.26 16.23c0 .2-.32.37-.71.37H1.25c-.4 0-.71-.16-.71-.37V3.55c0-.2.32-.37.7-.37h21.3c.4 0 .72.17.72.37v12.68z" id="qub"/>
      <filter x="-10.6%" y="-18%" width="121.2%" height="135.9%" filterUnits="objectBoundingBox" id="qua">
        <feMorphology radius=".41" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
        <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
        <feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.5 0" in="shadowBlurOuter1"/>
      </filter>
    </defs>
    <g id="quizzes-fill" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path d="M23.87 14.55c0 .2-.28.37-.62.37H4.55c-.35 0-.63-.17-.63-.37V1.87c0-.2.28-.37.63-.37h18.7c.34 0 .62.16.62.37v12.68z" stroke="#878787" strokeWidth="1.82" fill="#2D2D2D"/>
      <g transform="translate(2 1)">
        <use fill="#000" filter="url(#qua)" xlinkHref="#qub"/>
        <use stroke="#878787" strokeWidth="1.82" fill="#2D2D2D" xlinkHref="#qub"/>
      </g>
    </g>
  </svg>

export const QuestionsIcon = () =>
  <svg width="24" height="20" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path d="M68 9a10 10 0 1 0 0 20 10 10 0 0 0 0-20zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92A3.4 3.4 0 0 0 69 22h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41a2 2 0 0 0-2-2 2 2 0 0 0-2 2h-2a4 4 0 1 1 8 0c0 .88-.36 1.68-.93 2.25z" id="q"/>
    </defs>
    <use id="questions-fill" fill="#878787" xlinkHref="#q" transform="translate(-58 -9)" fillRule="evenodd"/>
  </svg>

export const TeamIcon = () =>
  <svg width="24" height="20" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill="none">
      <path d="M0-2h24v24H0z"/>
      <path id="team-fill" d="M6 18.1c0-3.78 4.5-3.02 4.5-4.53 0 0 .09-1-.41-1.52A3.5 3.5 0 0 1 9 9.4C9 7.52 10.34 6 12 6s3 1.52 3 3.4c0 1.25-.46 2.02-1.1 2.65-.48.5-.4 1.52-.4 1.52 0 1.51 4.5.75 4.5 4.54 0 0-1.2.89-6 .89s-6-.9-6-.9zM5.49 13.97c-.57-.03-1.07-.07-1.52-.12C1.66 13.6 1 13.11 1 13.11c0-3.79 4.5-3.03 4.5-4.54 0 0 .09-1-.41-1.52A3.5 3.5 0 0 1 4 4.4C4 2.52 5.34 1 7 1s3 1.52 3 3.4M18.51 13.97c.57-.03 1.07-.07 1.52-.12 2.31-.25 2.97-.74 2.97-.74 0-3.79-4.5-3.03-4.5-4.54 0 0-.09-1 .41-1.52A3.5 3.5 0 0 0 20 4.4C20 2.52 18.66 1 17 1s-3 1.52-3 3.4" stroke="#878787"/>
    </g>
  </svg>
