import Dashboard from '../layouts/Dashboard'
import LoginView from '../layouts/Login'
import Signout from '../layouts/Signout'

var indexRoutes = [
  { path: '/login', name: 'Login', component: LoginView },
  { path: '/signout', name: 'Login', component: Signout },
  { path: '/', name: 'Dashboard', component: Dashboard }
]

export default indexRoutes
