import React, { Component } from 'react'
import { withApollo } from 'react-apollo'

import LoadingSpinner from '../../components/Loading'

class Signout extends Component {
  async componentDidMount () {
    await this.props.client.resetStore()
    localStorage.removeItem('Authorization')
    setTimeout(() => {
      this.props.history.push('/login')
    }, 300)
  }
  render () {
    return <LoadingSpinner />
  }
}

export default withApollo(Signout)
