import { ApolloClient } from 'apollo-client'
import { from } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'

const errorLink =
  onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`))
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
    }
    if (networkError && networkError.statusCode === 401) {
      localStorage.removeItem('Authorization')
    }
  })

export const httpLink =
  new HttpLink({ uri: process.env.REACT_APP_API_URL })

const authLink = (operation, forward) => {
  const token = localStorage.getItem('Authorization')
  const headers = {}
  if (token) {
    headers.authorization = `Bearer ${token}`
  }
  operation.setContext(context => ({
    ...context,
    headers: {
      ...context.headers,
      ...headers
    }
  }))
  return forward(operation)
}

export default new ApolloClient({
  link: from([
    errorLink,
    authLink,
    httpLink
  ]),
  cache: new InMemoryCache()
})
