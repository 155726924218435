import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { graphql } from 'react-apollo'
import { currentAdmin } from '../actions/admin'

export default (PrivateRoute) => {
  class AuthHoc extends Component {
    render () {
      if (!localStorage.getItem('Authorization')) {
        return <Redirect to="/login" />
      }
      const { data: { currentAdmin, loading, error } } = this.props
      if (loading) return null
      if (error) {
        localStorage.removeItem('Authorization');
        console.log(error);
        return <Redirect to="/login" />;
      }
      return (
        currentAdmin ? (
          <PrivateRoute {...this.props} />
        ) : (
          <Redirect to="/login" />
        )
      )
    }
  }
  return graphql(currentAdmin, { fetchPolicy: 'network-only' })(AuthHoc)
}
